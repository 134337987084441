var exports = {};
var hasOwn = Object.prototype.hasOwnProperty;
/**
 * Expose `MapCache`
 */

exports = MapCache;
/**
 * Creates a cache object to store key/value pairs.
 *
 * ```js
 * var cache = new MapCache();
 * ```
 *
 * @api public
 */

function MapCache(data) {
  this.__data__ = data || {};
}
/**
 * Adds `value` to `key` on the cache.
 *
 * ```js
 * cache.set('foo', 'bar');
 * ```
 *
 * @param {String} `key` The key of the value to cache.
 * @param {*} `value` The value to cache.
 * @returns {Object} Returns the `Cache` object for chaining.
 * @api public
 */


MapCache.prototype.set = function mapSet(key, value) {
  if (key !== "__proto__") {
    this.__data__[key] = value;
  }

  return this;
};
/**
 * Gets the cached value for `key`.
 *
 * ```js
 * cache.get('foo');
 * //=> 'bar'
 * ```
 *
 * @param {String} `key` The key of the value to get.
 * @returns {*} Returns the cached value.
 * @api public
 */


MapCache.prototype.get = function mapGet(key) {
  return key === "__proto__" ? undefined : this.__data__[key];
};
/**
 * Checks if a cached value for `key` exists.
 *
 * ```js
 * cache.has('foo');
 * //=> true
 * ```
 *
 * @param {String} `key` The key of the entry to check.
 * @returns {Boolean} Returns `true` if an entry for `key` exists, else `false`.
 * @api public
 */


MapCache.prototype.has = function mapHas(key) {
  return key !== "__proto__" && hasOwn.call(this.__data__, key);
};
/**
 * Removes `key` and its value from the cache.
 *
 * ```js
 * cache.del('foo');
 * ```
 * @title .del
 * @param {String} `key` The key of the value to remove.
 * @returns {Boolean} Returns `true` if the entry was removed successfully, else `false`.
 * @api public
 */


MapCache.prototype.del = function mapDelete(key) {
  return this.has(key) && delete this.__data__[key];
};

export default exports;